import { template as template_6b3a858979b242a29cc2a7a13520863a } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_6b3a858979b242a29cc2a7a13520863a(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
