import { template as template_e731310eec78402c9abb9aad86a28c75 } from "@ember/template-compiler";
const SidebarSectionMessage = template_e731310eec78402c9abb9aad86a28c75(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
