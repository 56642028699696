import { template as template_4fad2213856d49cb8048140e987fb958 } from "@ember/template-compiler";
const FKText = template_4fad2213856d49cb8048140e987fb958(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
