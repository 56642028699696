import { template as template_b4d61f6de739466ba903df81e61fa21e } from "@ember/template-compiler";
const FKLabel = template_b4d61f6de739466ba903df81e61fa21e(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
