import { template as template_77ab7f70311a43baa0ad5b5f4ed15b90 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_77ab7f70311a43baa0ad5b5f4ed15b90(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
